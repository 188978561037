import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GlobalTop from "../components/globalTop"
import GlobalOfferTiles from "../components/globalOfferTiles"

const Offer = () => (
  <Layout>
    <SEO title="Oferta" />

    <GlobalTop title="Oferta" />

    <section className="offer-content">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-xl-6 col-lg-5">
            <h2 className="section--header">
              To najlepsi specjaliści
              <strong>i innowacyjne metody lecznicze</strong>
            </h2>
            <p>
              Jesteśmy specjalistyczną placówką medyczną oferującą szeroki
              pakiet usług. Nasz zespół tworzą profesjonaliści z bogatym
              doświadczeniem i pasją do swojego zawodu. Dokładamy wszelkich
              starań, aby Pacjenci opuszczający nasze gabinety zapomnieli o bólu
              i dyskomforcie.
            </p>
            <p>
              Nowoczesne wyposażone wnętrza pozwalają nam świadczyć usługi na
              najwyższym poziomie. Korzystamy z certyfikowanego sprzętu
              renomowanych producentów. W swoich szeregach mamy ekspertów,
              którzy zajmą się Twoim ciałem i duszą. Gwarantujemy wiedzę i
              doświadczenie, ale też otwartość i indywidualne podejście do
              każdego przypadku. Pomaganie ludziom to dla nas motywacja do
              działania, a uśmiech na twarzy zdrowego, zadowolonego pacjenta to
              sens naszej pracy!
            </p>
          </div>
          <div className="col-xl-6 col-lg-7 col-md-8">
            <GlobalOfferTiles />
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default Offer
